import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import { useSignIn, useSignUp, useAuth } from "@clerk/clerk-react";
import LayoutMinimal from "../components/Layout/Minimal";
import Button from "../components/Elements/Button"
import { useSession } from '@clerk/clerk-react'
import { Clerk } from '@clerk/clerk-js'



const Login = () => {
  const { signIn } = useSignIn();
const { signUp, setActive } = useSignUp();
const { getToken, isSignedIn } = useAuth();
const [loading, setLoading] = useState(false);
const [errorClerk, setError] = useState(null);
const { isLoaded, session} = useSession()
const [reset, setReset] = useState(false);
const clerk = new Clerk(process.env.GATSBY_CLERK_PUBLISHABLE_KEY);

useEffect(() => {
  const handleClerkSession = async () => {
    try {
      // console.log(errorClerk, "HIerror");

      if (!clerk) {
        console.error("Clerk instance is not available");
        return;
      }

      if (reset === false && errorClerk !== null) {
        // console.log("checking...........");

        // Ensure Clerk is fully loaded before accessing session
        await clerk.load();

        if (!clerk.isLoaded) {
          console.error("Clerk failed to load properly");
          return;
        }

        console.log("Clerk loaded successfully");

        if (clerk.session) {
          await clerk.session.remove();
          console.log("User signed out before authentication");

          setReset(true);
          setError(null);
          window.location.href = "/login-sso";
        } else {
          console.warn("No active session found to remove.");
        }
      }
    } catch (error) {
      console.error("Error handling Clerk session:", error);
      window.location.href = "/login-sso";

    }
  };

  handleClerkSession();
}, [errorClerk]);


if (!signIn || !signUp) {
  return (
    <LayoutMinimal>
      <div className="flex flex-col items-center mx-auto">
        <h1 className="text-lg font-bold mb-4">Loading...</h1>
      </div>
    </LayoutMinimal>
  );
}


const handleSignIn = async (e) => {
  e.preventDefault();
  const email = e.target.email.value;

  if(reset === false) {
  try {
    // Check if user exists but needs to transfer an account
    const userExistsButNeedsToSignIn =
      signUp.verifications?.externalAccount?.status === "transferable" &&
      signUp.verifications?.externalAccount?.error?.code === "external_account_exists";

    console.log(userExistsButNeedsToSignIn, "userExistsButNeedsToSignIn");

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true });
      if (res.status === "complete") {
        setActive({ session: res.createdSessionId });
        console.log("Sign-in completed via account transfer.");
        return;
      }
    }

    // Check if a new user needs to be created
    const userNeedsToBeCreated = signIn.firstFactorVerification?.status === "transferable";
    console.log(userNeedsToBeCreated, "userNeedsToBeCreated");

    if (userNeedsToBeCreated) {
      console.log("Creating a new account...");
      const res = await signUp.create({ transfer: true });

      if (res.status === "complete") {
        setActive({ session: res.createdSessionId });
        console.log(res, "res");
        console.log("New account created. Signing in...");
      } else {
        console.error("Error during sign-in after account creation:");
      }
      return;
    }

    // Attempt to sign in with Enterprise SSO for existing users
    const res = await signIn.authenticateWithRedirect({
      identifier: email,
      strategy: "enterprise_sso",
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/azure",
    });

    console.log("Sign-in response:", res);
  
  } catch (err) {
    console.error("Error during sign-in:", err);
    setError(err.message);
    setReset(false);


    // Handle "user not found" error and attempt sign-up
    if (err.errors?.some((error) => error.code === "identifier_not_found")) {
      console.log("User not found. Attempting to sign up...");
      try {
        // Add sign-up logic here if needed
      } catch (signUpError) {
        setError(signUpError.message);
        setReset(false);
        console.error("Error during sign-up:", signUpError);
      }
    }
  }
}
};


// const sendTokenToBackend = async (token) => {
//   try {
//     const response = await fetch("/azure", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ token }),
//     });

//     if (response.ok) {
//       navigate("/");
//     } else {
//       console.error("Failed to send token:", response.statusText);
//       navigate("/login_failed");
//     }
//   } catch (error) {
//     console.error("Error sending token:", error);
//   }
// };



if (loading) {
  return (
    <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
    </div>
  );
}

return (
  <LayoutMinimal>
    <div className="flex flex-col items-center mx-auto">
      <h1 className="text-lg font-bold mb-4">Sign In with Enterprise SSO</h1>
      <form
        onSubmit={(e) => {
          handleSignIn(e);
          e.target.reset();
        }}
        className="flex flex-col gap-4 w-80"
      >
        <input
          type="email"
          name="email"
          placeholder="Enter email address"
          className="p-2 border rounded"
        />
        <Button className="mx-auto" type="submit">
          Sign in with Enterprise SSO
        </Button>
      </form>
      {errorClerk && <h1 className=" text-red mt-4">{errorClerk}</h1>}
    </div>
  </LayoutMinimal>
);
};

export default Login;